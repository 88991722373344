import { render, staticRenderFns } from "./AddNotesDialog.vue?vue&type=template&id=3388b0bf&scoped=true&"
import script from "./AddNotesDialog.vue?vue&type=script&lang=js&"
export * from "./AddNotesDialog.vue?vue&type=script&lang=js&"
import style0 from "./AddNotesDialog.vue?vue&type=style&index=0&id=3388b0bf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3388b0bf",
  null
  
)

export default component.exports
<template>
  <div class="no-data" v-if="!loading && !note">
    <Empty :min-height="500" headline="No notes yet" icon="mdi-book-outline" />
  </div>
  <v-progress-linear v-else-if="loading" :indeterminate="true" />
  <v-card
    outlined
    flat
    class="rounded-10"
    v-else
    min-height="500"
    style="height: 100%"
  >
    <v-card-title class="note-title">
      <h2 class="primary--text font-weight-bold heading pl-5">
        {{ note.title | ucwords }}
      </h2>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            color="primary"
            v-on="on"
            class="action-btn"
            @click="$emit('collaborator', note.users)"
          >
            <v-icon>mdi-account-supervisor-outline</v-icon>
          </v-btn>
        </template>
        <span>Manage Note Collaborators</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            color="primary"
            class="action-btn"
            v-on="on"
            @click="$emit('editNotes', note)"
          >
            <v-icon>mdi-circle-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>Edit Note</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            class="action-btn"
            v-on="on"
            color="primary"
            @click="$emit('deleteNotes', note)"
          >
            <v-icon>mdi-delete-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>Delete Note</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="note-content">
      <v-row
        dense
        class="mb-3 d-flex fill-height"
        align="stretch"
        justify="center"
      >
        <v-spacer />
        <p class="mr-3 caption py-0 my-0 text-right primary--text">
          <span>
            Created :
            {{ note.created_at | format('MMM D YYYY HH:mm A') }}
          </span>
          <br />
          <span v-if="note.updated_at">
            Last Updated: {{ note.updated_at | format('MMM D YYYY HH:mm A') }}
          </span>
        </p>
        <v-col cols="12">
          <Editor
            class="fullwidth"
            :value="note.content"
            :editable="false"
          ></Editor>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row dense class="pa-3">
        <v-col cols="12">Collaborators:</v-col>
        <v-col
          v-for="collaborator in note.users"
          :key="collaborator.id"
          class="my-1 flex"
          :cols="mdAndUp ? 4 : 12"
        >
          <Avatar :user="collaborator" :display="collaborator.fullname" />
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import Editor from '@/common/Editor/Editor.vue'
export default {
  name: 'NoteForm',
  components: {
    Editor
  },
  props: {
    note: Object,
    loading: Boolean
  }
}
</script>
<style lang="scss" scoped src="./NoteForm.scss"></style>
<style scoped src="./NoteForm.css"></style>
<style type="text/css">
.content img,
.content video {
  max-width: 100%;
}
</style>

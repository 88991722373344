<template>
  <CustomDialog
    title="`Edit Note ${note.title || ''}`"
    v-model="showEditDialog"
    @click:close="cancel"
    button2-text="Save"
    @button1="cancel"
    @button2="update"
    max-width="800"
    :main-btn-disabled="!valid"
  >
    <template v-slot:content>
      <v-row dense class="custom-dialog" v-if="note">
        <v-col cols="12">
          <label class="primary--text subtitle-2"> Title </label>
          <v-text-field
            v-model="payload.title"
            outlined
            clear-icon="mdi-close-circle-outline"
            clearable
            dense
            :rules="[requiredRule()]"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="12">
          <label class="primary--text subtitle-2"> Contents </label>
          <Editor required v-model="payload.content" min-height="150"></Editor>
        </v-col>
      </v-row>
    </template>
  </CustomDialog>
</template>
<script>
import Editor from '@/common/Editor/Editor.vue'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
  components: {
    Editor,
    CustomDialog
  },

  props: {
    value: { type: Boolean, default: false },
    note: { type: Object, required: true }
  },
  data: () => ({
    payload: {
      id: null,
      title: null,
      content: null
    }
  }),
  watch: {
    note: {
      handler: function (val) {
        this.payload = {
          id: val.id,
          title: val.title,
          content: val.content
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    showEditDialog: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    valid() {
      return this.payload.title &&
        this.payload.content &&
        this.payload.content !== '<p></p>'
        ? true
        : false
    }
  },

  methods: {
    cancel() {
      this.showEditDialog = null
    },
    update() {
      if (!this.payload.title) {
        this.appSnackbar('Title is required', 'error')
        this.$event.$emit('btnloading_off', false)
        return
      }
      if (!this.payload.content || this.payload.content === '<p></p>') {
        this.appSnackbar('Description is required', 'error')
        this.$event.$emit('btnloading_off', false)
        return
      }
      this.$emit('update', this.payload)
    }
  }
}
</script>
<style lang="scss" scoped></style>

<template>
  <CustomDialog
    title="Collaborators"
    v-model="open"
    button2-text="Save"
    @button1="open = false"
    @button2="save"
    :main-btn-disabled="!valid"
  >
    <template v-slot:content>
      <v-sheet class="fullwidth">
        <v-item-group
          v-model="selected"
          class="pa-2"
          multiple
          active-class="primary"
          show-arrows
        >
          <v-row align="center" justify="center" class="flex-wrap">
            <v-col v-for="user in users" :key="user.id" cols="12" md="4" sm="6">
              <v-item :value="user.id" v-slot="{ toggle }">
                <v-card
                  :disabled="owner === user.id"
                  :color="inSelected(user.id) ? undefined : 'grey lighten-1'"
                  class="
                    pa-2
                    d-flex
                    fullwidth
                    align-center
                    justify-center
                    flex-column
                    text-center
                  "
                  height="180"
                  @click="toggle"
                >
                  <v-avatar size="80">
                    <v-img
                      :lazy-src="settings.loader"
                      contain
                      :src="user.image_url"
                    ></v-img>
                  </v-avatar>
                  <strong
                    :class="[
                      inSelected(user.id) ? 'white--text' : 'primary--text'
                    ]"
                  >
                    {{ user.fullname }}
                  </strong>
                  <strong class="secondary--text">{{ user.job_title }}</strong>
                  <strong v-if="owner === user.id" class="error--text">
                    Note Owner
                  </strong>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-sheet>
    </template>
  </CustomDialog>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
//Components
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    CustomDialog
  },

  props: {
    value: { type: Boolean, default: false },
    collaborators: { type: Array },
    owner: { type: Number }
  },

  data: () => ({
    open: false,
    selected: [],
    items: [],
    loading: false
  }),

  watch: {
    open(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.open = val
    },
    collaborators: {
      handler: function (val) {
        if (val && val.length) {
          this.selected = _cloneDeep(val).map((i) =>
            i.hasOwnProperty('id') ? i.id : i
          )
        } else {
          this.selected = []
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['users']),
    valid() {
      return this.selected.length > 0 ? true : false
    }
  },
  methods: {
    inSelected(id) {
      return this.selected.includes(id) ? true : false
    },
    save() {
      this.$emit('save', { users_id: this.selected })
    }
  }
}
</script>

<template>
  <CustomDialog
    @button1="closeDialog"
    @button2="save"
    @click:close="closeDialog"
    button2-text="Save"
    title="Add Notes"
    v-model="showDialog"
    max-width="800"
  >
    <template v-slot:content>
      <v-row class="custom-dialog" dense>
        <v-col cols="12">
          <label class="primary--text subtitle-2"> Title </label>
          <v-text-field
            clearable
            dense
            clear-icon="mdi-close-circle-outline"
            outlined
            :rules="[requiredRule()]"
            hide-details="auto"
            v-model.trim="payload.title"
          />
        </v-col>
        <v-col cols="12">
          <label class="primary--text subtitle-2"> Contents </label>
          <Editor min-height="150" v-model="payload.content" required></Editor>
        </v-col>
      </v-row>
    </template>
  </CustomDialog>
</template>
<script>
import Editor from '@/common/Editor/Editor.vue'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
  components: {
    Editor,
    CustomDialog
  },

  props: {
    value: Boolean
  },

  data: () => ({
    payload: {
      title: null,
      content: null
    }
  }),
  watch: {
    showDialog: {
      handler: function (val) {
        if (val) {
          this.payload = {
            title: null,
            content: null
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    valid() {
      return this.payload.title && this.payload.content ? true : false
    },
    showDialog: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },

  methods: {
    save() {
      if (!this.payload.title) {
        this.appSnackbar('Title is required', 'error')
        this.$event.$emit('btnloading_off', false)
        return
      }
      if (!this.payload.content || this.payload.content === '<p></p>') {
        this.appSnackbar('Description is required', 'error')
        this.$event.$emit('btnloading_off', false)
        return
      }
      this.$emit('save', this.payload)
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    closeDialog() {
      this.showDialog = false
    }
  }
}
</script>
<style lang="scss" scoped>
.note-description {
  border-radius: 8px;
  border: none;
  background-color: $white;
  margin: 0 5px;
}
</style>
